import { useContext } from 'react'

import { Enterprise } from 'models/enterprise'
import { Plan, Address, Client, ClientAddress, AddressType, Identification, Order, User } from 'models'

import { ListResult, QueryOptions, ReadResult } from 'interfaces/queryOptions'
import { ClientEditContext } from '../contexts/ClientEditContext'
import { ClientStatus } from 'models/clientStatusHistory'

export type UseEditClientProps = {
  isSaving: boolean

  isLoadingClient: boolean
  client: ReadResult<Client> | undefined
  setClient: (data: ReadResult<Client>) => void
  fetchClient: () => Promise<void>
  editClient: () => Promise<Record<string, string> | void>
  identification: Partial<Identification> | undefined
  clientStatus: ClientStatus | undefined
  setClientStatus: (newStatus: ClientStatus) => void
  updateClientStatus: (newStatus: ClientStatus) => void
  refreshCorporateData: () => Promise<void>
  isRefreshing: boolean

  wizard: string | undefined
  setWizard: (data: any) => void

  isLoadingAddress: boolean
  addresses: ReadResult<ClientAddress>[] | undefined
  setAddresses: (data: any) => void
  listAddresses: () => Promise<void>
  createAddress: (attributes: Address, type: AddressType) => Promise<ReadResult<ClientAddress> | undefined>
  editAddress: (id: number, attributes: Address, type: AddressType) => Promise<void>
  deleteAddress: (id: number) => any

  isLoadingAccessPlans: boolean
  accessPlan: ReadResult<Plan> | undefined
  setAccessPlan: (data: any) => void
  listAccessPlans: (options: QueryOptions) => Promise<ListResult<Plan> | undefined>

  isLoadingSubscription: boolean
  subscriptions: ReadResult<Order>[] | undefined
  setSubscriptions: (data: any) => void
  listSubscriptions: () => Promise<void>
  createSubscription: (attributes: any) => Promise<void>

  isLoadingEnterprise: boolean
  enterprise: ReadResult<Enterprise> | undefined
  setEnterprise: (data: ReadResult<Enterprise>) => void
  fetchEnterprise: () => Promise<void>
  createEnterprise: (attributes: any) => Promise<void>
  editEnterprise: (enterpriseUuid: string, attributes: any) => Promise<void>
  accounts: Record<string, boolean>
  setAccounts: (data: Record<string, boolean>) => void
  editAccounts: () => Promise<void>

  isLoadingUsers: boolean
  users: ReadResult<User>[] | undefined
  setUsers: (data: ReadResult<User>[]) => void
  listUsers: () => Promise<void>
  createUser: (attributes: Partial<User>) => Promise<void>
  editUser: (index: number, attributes: ReadResult<User>) => Promise<void>
  deleteUser: (id: number) => any
  sendEmail: (index: number) => Promise<void>

  language: string
}

export function useEditClient(): UseEditClientProps {
  const context = useContext(ClientEditContext)

  if (!context) {
    throw new Error('useEditClient must be used within an ClientProvider')
  }

  return context
}
