import { toast } from 'react-toastify'
import { useParams, useHistory } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { RefreshIcon, TrashIcon, XIcon } from '@heroicons/react/outline'

import { LoadingCircle } from 'components/Loading'
import { StepPanel } from '../components/StepPanel'
import { NotFoundPage } from 'components/NotFoundPage'
import { ReviewTable } from '../components/ReviewTable'
import { ProtectedSection } from 'components/ProtectedSection'
import { useConfirmationDialog } from 'components/ConfirmationDialog'
import { CancelButton, SimpleButton } from 'components/FormInputs/Button'
import { InputWithValidationError, SimpleCustomSelect } from 'components/FormInputs'

import config from 'config'
import { Errors } from 'types'
import { isEmpty } from 'utils'
import { showFreeChip } from 'services/chips'
import { translations } from '../translations'
import { ReadResult } from 'interfaces/queryOptions'
import { updateSteps, includeStatus } from '../helpers/utils'
import { ConnectivityCart, ConnectivityDetails } from '../types'
import { orderConnectivityStatus, orderStatus, orderBaseSteps } from '../helpers/constants'
import { Chip, Client, Order, OrderConnectivityStatus, OrderStatus, OrderStatusHistory, Plan, Role } from 'models'
import { addItems, approve, cancel, deliver, listItems, removeItems, show, updateItemStatus, updateStatus } from '../services'
import { Label } from 'components/Label'
// import StatusPanel from "../components/StepPanel/StatusPanel"

const shouldFetchChips = (currentStatus: string) => {
  return !(['pending', 'approved'].includes(currentStatus))
}

const makeUnknownChipDetails = (): ConnectivityDetails => {
  return {
    valid: false,
    saved: false,
    id: undefined,
    hint: () => (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
        Validando
      </span>
    ),
    attributes: {}
  }
}

const makeValidChipDetails = (data: ReadResult<Chip>): ConnectivityDetails => {
  return {
    valid: true,
    saved: false,
    id: data.id,
    hint: () => (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-emerald-100 text-emerald-800">
        Válido
      </span>
    ),
    attributes: data.attributes
  }
}

const makeInvalidChipDetails = (message: string): ConnectivityDetails => {
  return {
    valid: false,
    saved: false,
    id: undefined,
    hint: () => (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
        {message}
      </span>
    ),
    attributes: {}
  }
}

const makeSavedChipDetails = (data: Chip & { itemStatus: OrderConnectivityStatus }): ConnectivityDetails => {
  return {
    valid: true,
    saved: true,
    id: data.id,
    hint: () => (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
        Salvo
      </span>
    ),
    attributes: data,
    status: data.itemStatus
  }
}

export function ShowConnectivityOrder () {
  const { orderId } = useParams() as unknown as { orderId: number }

  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)
  const [disabled, setDisabled] = useState(false)

  const [steps, setSteps] = useState(orderBaseSteps.map(step => Object.assign({}, step)))

  const [order, setOrder] = useState<Order>()
  const [currentOrderStatus, setCurrentOrderStatus] = useState<OrderStatusHistory>()

  const [plan, setPlan] = useState<Partial<Plan>>()
  const [client, setClient] = useState<Partial<Client>>()

  const [serials, setSerials] = useState<Set<string>>(new Set())
  const [cartDetails, setCartDetails] = useState<ConnectivityCart>({})

  const [currentSerial, setCurrentSerial] = useState<string>()
  const [chipsUnderValidation, setChipsUnderValidation] = useState<Set<string>>(new Set())
  const [validationQueue, setValidationQueue] = useState<string[]>([])

  const [showConfirmationDialog] = useConfirmationDialog()

  const [errors, setErrors] = useState<Errors>()

  const updateOrderStatusHistory = useCallback(
    (currentStatus: OrderStatusHistory) => {
      setCurrentOrderStatus(currentStatus)
      setSteps(updateSteps(steps, currentStatus.status))

      return currentStatus
    },
    [steps]
  )

  const setFocus = useCallback(
    () => {
      const element = document.getElementById('serial')
      element?.focus()
    },
    []
  )

  useEffect(
    () => {
      if (!orderId) return

      const fetchOrderChips = async (data: Record<string, any>[]) => {
        const details: ConnectivityCart = {}

        const orderItemDataMap = data.reduce((map, d) => {
          map[d.attributes.item_id] = d.attributes
          return map
        }, {} as Record<string, any>)

        try {
          const items = await listItems(orderId)

          for (const item of items.data) {
            details[item.attributes.serial as string] = makeSavedChipDetails({
              ...item.attributes,
              id: item.id,
              itemStatus: orderItemDataMap[item.id].status
            } as any)
            serials.add(item.attributes.serial as string)
          }

          setCartDetails({ ...cartDetails, ...details })
          setSerials(new Set(serials))
        } catch (err) {
          toast.error('Falha ao buscar os chips do pedido')
        }
      }

      const fetchOrder = async () => {
        try {
          const order = await show(orderId, {
            includes: {
              client: ['id', 'account_name'],
              plan: ['id', 'name', 'meta', 'logistics'],
              status: [],
              order_chips: ['item_id', 'status']
            }
          })

          setOrder(order as Order)

          setPlan({
            id: order?.relationships?.plan?.id,
            uuid: order?.relationships?.plan?.uuid,
            ...(order?.relationships?.plan?.attributes ?? {})
          })

          setClient({
            id: order?.relationships?.client?.id,
            uuid: order?.relationships?.client?.uuid,
            ...(order?.relationships?.client?.attributes ?? {})
          })

          const orderStatus = {
            id: order?.relationships?.status[0].id,
            ...order?.relationships?.status[0].attributes
          }

          const currentStatus = updateOrderStatusHistory(orderStatus)

          if (shouldFetchChips(currentStatus.status)) {
            await Promise.all([
              fetchOrderChips(order?.relationships?.order_chips ?? []),
            ])
          }

          setIsLoading(false)
        } catch (err: any) {
          history.push('/orders')
          toast.error(err.suggestedMessage ?? 'Falha ao buscar o pedido')
        }
      }

      fetchOrder()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderId]
  )

  const onCancelConfirmation = useCallback(
    async () => {
      try {
        await cancel(orderId)
        const data = await show(orderId, {
          attributes: ['id'],
          includes: { status: [] }
        })

        const orderStatus = {
          id: data.relationships?.status[0].id,
          ...data.relationships?.status[0].attributes
        }
        updateOrderStatusHistory(orderStatus)
        toast.success('Pedido cancelado com sucesso!')
      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Não foi possível cancelar o pedido!')
      }
    },
    [orderId, updateOrderStatusHistory]
  )

  const onCancelRequest = useCallback(
    () => {
      showConfirmationDialog({
        title: 'Cancelar pedido',
        message: 'Tem certeza que deseja cancelar este pedido?',
        onConfirm: onCancelConfirmation
      })
    },
    [onCancelConfirmation, showConfirmationDialog]
  )

  const onApprovalConfirmation = useCallback(
    async () => {
      try {
        await approve(orderId)
        const data = await show(orderId, {
          attributes: ['id'],
          includes: { status: [] }
        })

        const orderStatus = {
          id: data.relationships?.status[0].id,
          ...data.relationships?.status[0].attributes
        }
        updateOrderStatusHistory(orderStatus)
        toast.success('Pedido aprovado com sucesso!')
      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Não foi possível aprovar o pedido!')
      }
    },
    [orderId, updateOrderStatusHistory]
  )

  const onApprovalRequest = useCallback(
    () => {
      showConfirmationDialog({
        title: 'Aprovar pedido',
        message: 'Tem certeza que deseja aprovar este pedido?',
        onConfirm: onApprovalConfirmation
      })
    },
    [onApprovalConfirmation, showConfirmationDialog]
  )

  const onStatusChangeConfirmation = useCallback(
    async (newStatus: OrderStatus) => {
      try {
        newStatus === OrderStatus.delivered
          ? await deliver(orderId)
          : await updateStatus(orderId, newStatus)

        const data = await show(orderId, {
          attributes: ['id'],
          includes: { status: [] }
        })

        const orderStatus = {
          id: data.relationships?.status[0].id,
          ...data.relationships?.status[0].attributes
        }
        updateOrderStatusHistory(orderStatus)

        toast.success('Estado atualizado com sucesso!')
      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Não foi possível atualizar o estado do pedido!')
      }
    },
    [orderId, updateOrderStatusHistory]
  )

  const onStatusChangeRequest = useCallback(
    (status: OrderStatus|'') => {
      if (status === '') return
      showConfirmationDialog({
        title: 'Atualizar estado',
        message: `Alterar o estado do pedido para: ${translations.order_status[status]}?`,
        onConfirm: () => onStatusChangeConfirmation(status)
      })
    },
    [onStatusChangeConfirmation, showConfirmationDialog]
  )

  const validateChip = useCallback(
    async (serial: string) => {
      if (chipsUnderValidation.has(serial) || validationQueue.includes(serial)) {
        return
      } else if (chipsUnderValidation.size < config.max_concurrent_validations) {
        chipsUnderValidation.add(serial)
        setChipsUnderValidation(new Set(chipsUnderValidation))
        setCartDetails({ ...cartDetails, [serial]: makeUnknownChipDetails() })
      } else {
        setValidationQueue([...validationQueue, serial])
        setCartDetails({ ...cartDetails, [serial]: makeUnknownChipDetails() })
        return
      }

      const chip = await showFreeChip({
        filters: [
          { key: 'serial', value: serial },
          { key: 'from_softruck', value: true },
          { key: 'enterprise_id', op: 'is', value: 'null' }
        ]
      })

      // The user has removed this chip from the cart while it was still under validation
      if (!chipsUnderValidation.has(serial)) return
      chipsUnderValidation.delete(serial)
      setChipsUnderValidation(new Set(chipsUnderValidation))

      if (chip && plan?.meta?.restriction.chips.service_provider) {
        if (chip.attributes.service_provider !== plan?.meta?.restriction.chips.service_provider) {
          cartDetails[serial] = makeInvalidChipDetails('Inválido')
        } else {
          cartDetails[serial] = makeValidChipDetails(chip)
        }
      } else {
        cartDetails[serial] = makeInvalidChipDetails('Inválido')
      }

      if (validationQueue.length) {
        const nextSerial = validationQueue.shift() as string
        chipsUnderValidation.add(nextSerial)
        cartDetails[nextSerial] = makeUnknownChipDetails()
        setValidationQueue([...validationQueue])
      }

      setDisabled(false)
      setFocus()
      setCartDetails({ ...cartDetails })
      setChipsUnderValidation(new Set(chipsUnderValidation))
    },
    [cartDetails, chipsUnderValidation, plan?.meta?.restriction.chips.service_provider, setFocus, validationQueue]
  )

  const onAddToCart = useCallback(
    async (serial?: string) => {
      if (!serial || serial === '') return

      const pattern = /\d{20}/
      if (!serial.match(pattern)) {
        setErrors({ serial: 'O serial informado deve conter 20 dígitos!' })
        return
      }

      if (serials.has(serial)) {
        setErrors({ serial: 'O chip já está no pedido!' })
        return
      }

      if (serials.size >= (order?.quantity ?? 0)) {
        toast.error('O pedido já está cheio!')
        return
      }

      setDisabled(true)

      serials.add(serial)
      setSerials(new Set(serials))

      await validateChip(serial)
      setCurrentSerial('')
      setErrors({ serial: null })
    },
    [order?.quantity, serials, validateChip]
  )

  const onRemoveFrontCart = useCallback(
    (serial: string) => {
      serials.delete(serial)
      setSerials(new Set(serials))
      setErrors({ serial: null })

      const validationIndex = validationQueue.indexOf(serial)

      if (validationIndex >= 0) {
        validationQueue.splice(validationIndex, 1)
        setValidationQueue([...validationQueue])
      }

      if (chipsUnderValidation.has(serial)) {
        chipsUnderValidation.delete(serial)
        setChipsUnderValidation(new Set(chipsUnderValidation))
      }

      if (cartDetails[serial]) {
        delete cartDetails[serial]
        setCartDetails({ ...cartDetails })
      }
    },
    [cartDetails, chipsUnderValidation, serials, validationQueue]
  )

  const onRemoveFromOrderConfirmation = useCallback(
    async (serial: string) => {
      if (!cartDetails[serial] || !cartDetails[serial].id) {
        toast.error('Não foi possível remover o chip do pedido')
        return
      }

      try {
        await removeItems(orderId, [cartDetails[serial].id as number])
        toast.success('Chip removido com sucesso!')

        serials.delete(serial)
        setSerials(new Set(serials))

        delete cartDetails[serial]
        setCartDetails({ ...cartDetails })

      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Não foi possível remover o chip do pedido')
      }
    },
    [cartDetails, orderId, serials]
  )

  const onRemoveFromOrderRequest = useCallback(
    (serial: string) => {
      showConfirmationDialog({
        title: 'Remover item do pedido',
        message: `Tem certeza que deseja remover o chip '${serial}' do pedido?`,
        onConfirm: () => onRemoveFromOrderConfirmation(serial)
      })
    },
    [onRemoveFromOrderConfirmation, showConfirmationDialog]
  )

  const onSave = useCallback(
    async () => {
      try {
        const cartItems = Array.from(serials)

        if (cartItems.length <= 0) return

        const cartContainsInvalidItems = cartItems.find((item) => cartDetails[item].id === undefined)

        if (cartContainsInvalidItems) {
          toast.error('Revise os chips inválidos e tente novamente!')
        } else {
          const newItems = cartItems.filter((item) => !cartDetails[item].saved)

          if (newItems.length <= 0) return

          await addItems(orderId, newItems.map((item) => cartDetails[item].id as number))
          toast.success('Chips adicionados com sucesso!')

          const newItemsDetails = newItems.reduce((cart, item) => {
            cart[item] = makeSavedChipDetails({
              id: cartDetails[item].id as number,
              ...cartDetails[item].attributes,
              itemStatus: OrderConnectivityStatus.ordered
            } as any)
            return cart
          }, {} as ConnectivityCart)

          setCartDetails({
            ...cartDetails,
            ...newItemsDetails
          })
        }
      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Falha ao tentar adicionar os chips ao pedido!')
      }
    },
    [cartDetails, orderId, serials]
  )

  const onSaveChangeItemStatus = useCallback(
    async (newStatus: OrderConnectivityStatus, serial: string) => {
      try {
        const data = [
          { attributes: { item_id: cartDetails[serial].id as number } }
        ]

        await updateItemStatus(orderId, newStatus, data)

        toast.success(`Estado ${translations.item_status[newStatus]} com sucesso!`)

        cartDetails[serial].status = newStatus

        setCartDetails({ ...cartDetails })
      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Não foi possível atualizar o estado do(s) chips(s)!')
      }
    },
    [cartDetails, orderId]
  )

  const onChangeItemStatusRequest = useCallback(
    async (newStatus: OrderConnectivityStatus, serial: string) => {
      showConfirmationDialog({
        title: 'Atualizar estado dos chips',
        message: `Tem certeza que deseja atualizar o estado do chip selecionado para '${translations.item_status[newStatus]}'?`,
        onConfirm: () => onSaveChangeItemStatus(newStatus, serial)
      })
    },
    [onSaveChangeItemStatus, showConfirmationDialog]
  )

  return (isLoading)
    ? <LoadingCircle/>
    : (!order ? (<NotFoundPage />) : (
    <div className="overflow-hidden px-4">
      <div className="flex flex-row justify-between py-4 m-4 animate-fade-in-down">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Pedido: {order.token} {currentOrderStatus?.status === 'canceled' && (
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              Cancelado
            </span>
          )}
        </h3>

        <div className="gap-x-2 flex flex-row justify-between">
          { includeStatus(currentOrderStatus?.status, [OrderStatus.pending]) && (
              <ProtectedSection roles={[Role.MANAGER, Role.FINANCE, Role.LOGISTICS]}>
                <SimpleButton onClick={onApprovalRequest}>
                  Aprovar
                </SimpleButton>
              </ProtectedSection>
            )
          }
          { !includeStatus(currentOrderStatus?.status, [OrderStatus.pending, OrderStatus.canceled, OrderStatus.delivered]) &&
            order.relationships?.plan.attributes.logistics &&
            (
              <ProtectedSection roles={[Role.MANAGER, Role.LOGISTICS]}>
                <div className="w-52">
                  <SimpleCustomSelect
                    options={orderStatus}
                    value={currentOrderStatus?.status}
                    onChange={(value) => onStatusChangeRequest(value as OrderStatus)}
                  />
                </div>
              </ProtectedSection>
            )
          }
        </div>
      </div>

      <StepPanel steps={steps}/>

      {/* <div className="py-4"></div> */}
      {/* <StatusPanel orderId={id} currentStatus={currentOrderStatus}/> */}

      <ReviewTable order={order} client={client} plan={plan} />

      {/* Add chips section */}
      <ProtectedSection roles={[Role.MANAGER, Role.LOGISTICS]}>
        {includeStatus(currentOrderStatus?.status, [OrderStatus.in_progress, OrderStatus.delivered]) && (
          currentOrderStatus?.status === OrderStatus.in_progress && (
            <>
              <div className="flex p-6 m-4 rounded border shadow space-x-4 items-center animate-fade-in-down">
                <div className="w-1/4">
                  <InputWithValidationError
                    autoFocus
                    label=""
                    type="text"
                    name="serial"
                    id="serial"
                    placeholder="serial"
                    value={currentSerial}
                    disabled={disabled || serials.size >= (order?.quantity ?? 0)}
                    onKeyPress={(e: any) => e.key === "Enter" ? onAddToCart(currentSerial) : null}
                    onChange={(value) => setCurrentSerial(value)}
                    error={errors?.serial}
                  />
                </div>
                <div className="mt-1">
                  <SimpleButton
                    disabled={serials.size >= (order?.quantity ?? 0)}
                    onClick={() => onAddToCart(currentSerial)}
                  >
                    Adicionar
                  </SimpleButton>
                </div>
                <div className="mt-1">
                  {`Adicionados: ${serials.size} de ${order.quantity}`}
                </div>
              </div>
            </>
          )
        )}
      </ProtectedSection>

      <div id="order-items-table">
        { !isEmpty(cartDetails) &&
          !includeStatus(currentOrderStatus?.status, [OrderStatus.pending, OrderStatus.approved, OrderStatus.in_line]) && (
          <div className="flex flex-col px-4 animate-fade-in-down">
            <div className="-my-2 sm:-mx-6 lg:-mx-8">
              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          { includeStatus(currentOrderStatus?.status, [OrderStatus.in_line, OrderStatus.in_progress, OrderStatus.updating_fiscal_data]) ? "Válido" : "Status"}
                        </th>
                        <th
                          scope="col"
                          className="p-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          Serial / Número
                        </th>
                        <th
                          scope="col"
                          className="p-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          Operadora
                        </th>
                        <th
                          scope="col"
                          className="p-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          APN
                        </th>
                        <th
                          scope="col"
                          className="p-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          APN - usuário
                        </th>
                        <th
                          scope="col"
                          className="p-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          APN - senha
                        </th>
                        <th
                          scope="col"
                          className="p-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-left bg-white divide-y divide-gray-200">
                      { Array.from(serials).reverse().map((serial, index) => (
                        <tr key={serial} className={index % 2 ? 'bg-gray-50' : 'bg-white'}>
                          <td className="p-3 whitespace-nowrap">
                            <div className="flex items-center">
                              { includeStatus(currentOrderStatus?.status, [OrderStatus.in_line, OrderStatus.in_progress, OrderStatus.updating_fiscal_data])
                                  ? cartDetails[serial].hint()
                                  : (
                                    <Label
                                      type="item_status"
                                      label={translations['item_status'][cartDetails[serial].status as string]}
                                      value={cartDetails[serial].status}
                                    />
                                  )
                              }
                            </div>
                          </td>
                          <td className="p-3 whitespace-nowrap">
                            <div className="text-xs text-gray-900">{cartDetails[serial].attributes.serial || serial || '-'}</div>
                            <div className="text-xs text-gray-500">{cartDetails[serial].attributes.number || '-'}</div>
                          </td>
                          <td className="p-3 whitespace-nowrap text-xs text-gray-500">{cartDetails[serial].attributes.service_provider || '-'}</td>
                          <td className="p-3 whitespace-nowrap text-xs text-gray-500">{cartDetails[serial].attributes.apn || '-'}</td>
                          <td className="p-3 whitespace-nowrap text-xs text-gray-500">{cartDetails[serial].attributes.apn_username || '-'}</td>
                          <td className="p-3 whitespace-nowrap text-xs text-gray-500">{cartDetails[serial].attributes.apn_password || '-'}</td>
                          <td className="p-3 whitespace-nowrap text-xs font-medium">
                          { includeStatus(currentOrderStatus?.status, [OrderStatus.in_progress]) && !cartDetails[serial].saved && (
                              <button
                                type="button"
                                className="-ml-px relative inline-flex items-center px-3 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                onClick={() => validateChip(serial)}
                              >
                                <RefreshIcon className="h-4 w-4" aria-hidden="true" />
                              </button>
                            )
                          }
                          { includeStatus(currentOrderStatus?.status, [OrderStatus.in_progress]) ?
                              cartDetails[serial].saved
                                ? (
                                  <button
                                    type="button"
                                    className="-ml-px relative inline-flex items-center px-3 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-red-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                    onClick={() => onRemoveFromOrderRequest(serial)}
                                  >
                                    <TrashIcon className="h-4 w-4" aria-hidden="true" />
                                  </button>
                                )
                                : (
                                  <button
                                    type="button"
                                    className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-red-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                    onClick={() => onRemoveFrontCart(serial)}
                                  >
                                    <XIcon className="h-4 w-4" aria-hidden="true" />
                                  </button>
                                )
                              : null
                          }
                          { includeStatus(currentOrderStatus?.status, [OrderStatus.delivered]) && (
                              <ProtectedSection roles={[Role.MANAGER, Role.FINANCE, Role.LOGISTICS]}>
                                {
                                  (['ordered'].includes(cartDetails[serial].status as string)) && (
                                    <SimpleCustomSelect
                                      options={orderConnectivityStatus}
                                      placeholder="Escolha um estado"
                                      value=''
                                      onChange={(value) => onChangeItemStatusRequest(value as OrderConnectivityStatus, serial)}
                                    />
                                  )
                                }
                              </ProtectedSection>
                            )
                          }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          )
        }

        <ProtectedSection roles={[Role.MANAGER, Role.LOGISTICS]}>
          { includeStatus(currentOrderStatus?.status, [OrderStatus.in_progress]) && (
            <div className="m-4 flex justify-end animate-fade-in-down">
              <button
                type="button"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none"
                onClick={() => onSave()}
              >
                Salvar
              </button>
            </div>
          )}
        </ProtectedSection>
      </div>

      { !includeStatus(currentOrderStatus?.status, [OrderStatus.canceled]) && (
        <div className="py-6 gap-x-2 flex flex-row justify-between animate-fade-in-down">
          <ProtectedSection roles={[Role.MANAGER, Role.FINANCE, Role.LOGISTICS]}>
            <div className="ml-3 text-left text-md">
              <label className="font-medium text-gray-700">
                Cancelar pedido
              </label>
              <p className="p-4 text-gray-500">
                Atenção: ao cancelar o pedido todos os chips salvos serão deletados.
              </p>

              <div className="">
                <CancelButton label="Cancelar" onClick={onCancelRequest} >
                </CancelButton>
              </div>
            </div>
          </ProtectedSection>
        </div>
        )
      }
    </div>
  ))
}
