import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import React, { useState, useCallback, useEffect } from 'react'
import { TrashIcon } from '@heroicons/react/solid'

import { FormSection } from 'components/Form'
import { PageHeader } from 'components/PageHeader'
import { ClientSearch, PlanSearch } from '../components/Searchs'
import { DatePickerWithError } from 'components/FormInputs/DatePicker'
import { InputWithAddon, InputWithValidationError, RadioListWithDescription, SimpleCustomSelect } from 'components/FormInputs'
import { fineModes } from 'components/FormInputs/options'
import { cleanAmount, cleanPercentageAmount, formatCurrency } from 'utils'

import { Errors } from 'types'
import { check } from 'utils/error'
import { amount, currency } from 'utils'
import { validate } from '../validation'
import { Cart, create } from '../services'
import { Client, Order, Plan } from 'models'
import { translations } from '../translations'
import { CreateOrderAttributes } from '../types'
import { Button, Close } from 'components/Buttons'
import { getFirstDayOfMonth } from 'utils/datetime'
import { ReadResult } from 'interfaces/queryOptions'
import { shippingMethods } from '../helpers/constants'
import { applyDiscount } from 'modules/common/utils'

function calculateSubtotal (cart: Cart[]) {
  let subtotal = 0
  cart.map(item => {
    const discount = (item.plan.attributes.amount as number ?? 0) - (item.order.discount || 0)
    subtotal += discount * (item.order.quantity ?? 1)
    return subtotal
  })

  return (subtotal / 100).toFixed(2).replace('.', ',')
}

export function Create() {
  const history = useHistory()

  const [cart, setCart] = useState<Cart[]>([])
  const [orderedAt, setOrderedAt] = useState(new Date())
  const [discount, setDiscount] = useState('0,00')

  const [plan, setPlan] = useState<ReadResult<Plan>>()
  const [order, setOrder] = useState<Partial<Order>>({})
  const [client, setClient] = useState<ReadResult<Client>>()

  const [isSaving, setIsSaving] = useState(false)
  const [shouldClear, setShouldClear] = useState(false)
  const [errors, setErrors] = useState<Errors>({})

  const onAddToCart = useCallback (
    () => {
      if (!client || !plan) return

      const transformAttributes = (attributes: Record<string, any>): Record<string, any> => {
        const {
          minimum_invoice_amount,
          fine_mode,
          fine_amount
        } = attributes

        return {
          minimum_invoice_amount: minimum_invoice_amount === '' ? null : cleanAmount(minimum_invoice_amount),
          fine_mode: fine_amount === '' ? null : fine_mode,
          fine_amount: fine_amount === '' ? null : fine_mode === null ? null :  fine_mode === 'fixed' ? cleanAmount(fine_amount) : cleanPercentageAmount(fine_amount)
        }
      }

      if (check(errors)) return

      order.quantity = order.quantity ?? 1
      order.discount = Math.round(+discount.replace(',', '.') * 100)
      order.ordered_at = orderedAt
      order.shipping_method = order.shipping_method ?? 'shipping'

      const { minimum_invoice_amount, fine_mode, fine_amount } = transformAttributes(order)

      order.minimum_invoice_amount = minimum_invoice_amount
      order.fine_mode = fine_mode
      order.fine_amount = fine_amount

      cart.push({ order, plan })
      setCart([...cart])

      setShouldClear(true)
      setOrder({})
      setDiscount('0,00')
      setPlan(undefined)
      setErrors({})
    },
    [cart, client, discount, errors, order, orderedAt, plan]
  )

  const onRemoveFrontCart = useCallback(
    (index: number) => {
      cart.splice(index, 1)
      setCart([...cart])
    },
    [cart]
  )

  const onCheckout = useCallback(
    async () => {
      if (!client || !cart.length) return

      setIsSaving(true)
      try {
        await create(cart, client)

        toast.success('Pedido(s) criado(s) com sucesso')
        setIsSaving(false)
        history.push('/orders')
      } catch (err: any) {
        setIsSaving(false)
        toast.error(err.suggestedMessage ?? 'Falha ao tentar salvar os dados do(s) pedido(s)')
      }
    },
    [cart, client, history]
  )

  const onAttributeChange = useCallback(
    (attr: keyof CreateOrderAttributes, value: any): void => {
      const error = validate(attr as any, value)

      if (attr === 'fine_amount' && order.fine_mode === 'fixed') {
        value = formatCurrency(value)
      }

      setOrder({ ...order, [attr]: value })
      setErrors({ ...errors, [attr]: error })
    },
    [errors, order]
  )

  const onOrderedAtChange = useCallback(
    (value: string) => {
      setOrderedAt(new Date(value))
    },
    []
  )

  const onDiscountChange = useCallback(
    (value: string) => {
      if (value === '-0,00' || value === '-0,0') {
        setDiscount('0,00')
      } else {
        const newAmount = amount(value.replace(/[^0-9]/g, ''))
        value.includes('-')
          ? setDiscount('-' + newAmount)
          : setDiscount(newAmount)
      }
    },
    []
  )

  useEffect(() => {
    if (plan?.attributes) {
      const { minimum_invoice_amount, fine_mode, fine_amount } = plan.attributes
  
      setOrder({
        ...order,
        minimum_invoice_amount: amount(minimum_invoice_amount),
        fine_mode,
        fine_amount: fine_mode === 'fixed' ? amount(fine_amount) : fine_amount
      })
    }
  }, [plan])

  return (
    <>
    <PageHeader title="Pedidos" action="Novos"/>

    <div className="flex flex-row space-x-10 px-4 animate-fade-in-down">
      <div className="w-1/2">
        <form onSubmit={(e) => { e.preventDefault(); onCheckout() }}>
          <FormSection title="Cliente">
            <ClientSearch
              disabled={cart.length > 0}
              onSelect={setClient}
              error={errors.client}
              planCountryId={plan?.relationships?.country.id}
            />
          </FormSection>

          <FormSection title="Plano">
            <PlanSearch
              clear={shouldClear}
              setClear={setShouldClear}
              onSelect={setPlan}
              onChange={setPlan}
              error={errors.plan}
              clientCountryId={client?.relationships?.country.id}
            />
          </FormSection>

          <FormSection title="Pedido">
            <div className="flex justify-between gap-8">
              <div className="w-1/2">
                <InputWithValidationError
                  label="Quantidade"
                  type="number"
                  name="quantity"
                  min="1"
                  value={order?.quantity ?? 1}
                  onChange={(value) => onAttributeChange('quantity', value)}
                  error={errors.quantity}
                  onWheel={(e: React.FocusEvent<HTMLInputElement>) => e.target.blur()}
                />
              </div>

              <div className="w-1/2">
                <DatePickerWithError
                  label="Pedido em"
                  name="orderedAt"
                  date={orderedAt}
                  minDate={getFirstDayOfMonth()}
                  maxDate={new Date()}
                  onChange={(value: any) => onOrderedAtChange(value)}
                />
              </div>
            </div>

            <div className="flex justify-between gap-8">
              <div className="w-1/2">
                <InputWithAddon
                  label="Desconto"
                  type="text"
                  frontAddon={currency(plan?.attributes.currency)}
                  disabled={plan?.attributes.restricted}
                  name="discount"
                  value={discount}
                  onChange={(value) => onDiscountChange(value)}
                />
              </div>

              <div className="w-1/2">
                <label className="block text-left text-sm font-medium text-gray-700">Valor com desconto</label>
                <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                  {
                    plan
                    ? `${currency(plan.attributes.currency)} ${amount(applyDiscount(plan.attributes.amount as number, +discount.replace(',', '.') * 100))}`
                    : ''
                  }
                </div>
              </div>
            </div>

            <div className="flex flex-row space-x-8">
              <div className="w-1/3">
                <SimpleCustomSelect
                  label="Tipo de multa"
                  options={fineModes}
                  value={order?.fine_mode}
                  onChange={(fineModes) => onAttributeChange('fine_mode', fineModes)}
                />
              </div>

              <div className="w-1/3">
                <InputWithAddon
                  label="Valor da multa (por unidade)"
                  name="fine_amount"
                  type="text"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  value={order?.fine_amount}
                  frontAddon={order.fine_mode === 'fixed' ? currency(plan?.attributes.currency) : '%'}
                  onChange={(value) => onAttributeChange('fine_amount', value)}
                  error={errors.amount}
                />
              </div>
            </div>

            <RadioListWithDescription
              label='Método de envio'
              options={shippingMethods}
              value={order?.shipping_method ?? 'shipping'}
              onChange={(value) => onAttributeChange('shipping_method', value)}
            />
          </FormSection>

          <div className="mt-4 flex justify-end items-center space-x-4">
            <Close linkTo="/orders" />
            <Button type="button" label="Adicionar" onClick={onAddToCart} />
          </div>
        </form>
      </div>

      {
        cart.length > 0 ? (
          <div className="w-5/12 relative animate-fade-in-down">
            <div className="flex px-5 w-full sticky top-0">
              <div className="h-full flex flex-col bg-white shadow-xl rounded-md border-md w-full mt-5">
                <div className="flex-1 py-6 px-4 ">
                  <div className="flex items-start justify-between">
                    <h3 className="text-xl text-left leading-6 font-medium text-gray-900">Carrinho</h3>
                    <div className="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                        onClick={() => setCart([])}
                      >
                        <span className="sr-only">Esvaziar</span>
                          <TrashIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>

                  <div className="mt-8">
                    <div className="flow-root">
                      <ul className="-my-6 divide-y divide-gray-200">
                        {
                          cart.map((item, index) => (
                            <li key={index} className="py-4 flex">
                              <div className="ml-4 flex-1 flex flex-col">
                                <div>
                                  <div className="mb-3 flex justify-between text-base font-medium text-gray-900">
                                    <h3>
                                      <p className="text-left whitespace-nowrap overflow-ellipsis overflow-hidden">{item.plan.attributes.name}</p>
                                      <p className="mt-1 text-left text-sm text-gray-500">Desconto</p>
                                      <p className=" text-left text-sm text-gray-500">Valor final</p>
                                    </h3>
                                    <div className="flex-col">
                                        <p className="ml-4 whitespace-nowrap overflow-ellipsis overflow-hidden">{currency(item.plan.attributes.currency)} {amount(item.plan.attributes.amount)}</p>
                                        <p className="mt-1 ml-4 text-sm text-right">{currency(item.plan.attributes.currency)} {amount(item.order.discount as number)}</p>
                                        <p className="ml-4 text-sm text-right">{currency(item.plan.attributes.currency)} {amount((item.plan.attributes.amount as number || 0) - (item.order.discount || 0))}</p>
                                    </div>
                                  </div>
                                  <p className="text-left text-sm text-gray-500">{translations.plan_type[item.plan.type as string]}</p>
                                </div>
                                <div className="flex-1 flex items-end justify-between text-sm">
                                  <p className="text-gray-500">Quantidade: {item.order.quantity}</p>

                                  <div className="flex">
                                    <button
                                      className="font-medium text-emerald-600 hover:text-red-500"
                                      onClick={() => onRemoveFrontCart(index)}
                                    >
                                      Remover
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="border-t border-gray-200 py-6 px-4">
                  <div className="mb-3 flex justify-between text-base font-medium text-gray-900">
                    <p>Subtotal</p>
                    <p>{cart.length ? `${currency(cart[0].plan.attributes.currency)} ${calculateSubtotal(cart)}`: ""}</p>
                  </div>
                  <p className="mt-0.5 text-sm text-gray-500">Frete e taxas serão calculados no checkout.</p>
                  <div className="mt-6">
                    <button
                      type="button"
                      disabled={isSaving}
                      className={`inline w-full px-5 py-3 border border-transparent rounded-md shadow-sm text-base font-medium ${isSaving ? " bg-gray-300" : "text-white bg-emerald-600 hover:bg-emerald-700"}`}
                      onClick={() => onCheckout()}
                    >
                      {
                        isSaving
                        ? (
                            <div className="flex justify-center items-center">
                              <svg className="animate-spin rounded-full h-5 w-5" viewBox="0 0 24 24" fill="none">
                                <circle className="opacity-25 stroke-current text-gray-700" cx="12" cy="12" r="10" strokeWidth="4"></circle>
                                <path className="opacity-75 fill-current text-gray-700" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                          </div>
                          )
                        : "Checkout"
                      }
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : null
      }
    </div>
    </>
  )
}
