import { toast } from 'react-toastify'
import { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { PencilIcon, RefreshIcon, TrashIcon, XIcon } from '@heroicons/react/outline'

import { Modal } from 'components/Modal'
import { LoadPage } from 'components/LoadPage'
import { StepPanel } from '../components/StepPanel'
import { NotFoundPage } from 'components/NotFoundPage'
import { ReviewTable } from '../components/ReviewTable'
import { ProtectedSection } from 'components/ProtectedSection'
import { useConfirmationDialog } from 'components/ConfirmationDialog'
import { CancelButton, SimpleButton } from 'components/FormInputs/Button'
import { InputWithValidationError, RadioListWithDescription, SimpleCustomSelect } from 'components/FormInputs'

import config from 'config'
import { Errors } from 'types'
import { date, isEmpty, pick } from 'utils'
import { translations } from '../translations'
import { ReadResult } from 'interfaces/queryOptions'
import { includeStatus, updateSteps } from '../helpers/utils'
import { HardwareDetails, HardwareCart } from '../types'
import { list as listDevices, showFreeDevice } from 'services/devices'
import { DatePickerWithError } from 'components/FormInputs/DatePicker'
import { orderBaseSteps, orderHardwareStatus, orderStatus, writeOffMotives } from '../helpers/constants'
import { Client, Device, Order, OrderHardwareStatus, OrderStatus, Plan, Role } from 'models'
import { addItems, approve, cancel, deliver, listItems, removeItems, show, updateItemFiscalData, updateItemStatus, updateStatus } from '../services'
import { FiscalData, WriteOffInvoiceMotives } from 'models/orderItem'
import { Label } from 'components/Label'

const writeOffOptions: Record<string, any> = {
  default: [{ value: '', label: 'Escolha um estado' }],
  canceled: [{ value: 'loss', label: 'Perda' }],
  returned: [{ value: 'return', label: 'Retorno' }],
  ownership_changed: [{ value: 'asset_sale', label: 'Venda de ativo' }]
}

const shouldFetchDevices = (currentStatus: string) => {
  return ['in_progress', 'updating_fiscal_data', 'ready_for_pick_up', 'shipping', 'delivered', 'canceled'].includes(currentStatus)
}

const makeUnknownDeviceDetails = (): HardwareDetails => {
  return {
    valid: false,
    saved: false,
    id: undefined,
    hint: () => (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
        Validando
      </span>
    ),
    attributes: {}
  }
}

const makeValidDeviceDetails = (data: ReadResult<Device>): HardwareDetails => {
  return {
    valid: true,
    saved: false,
    id: data.id,
    hint: () => (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-emerald-100 text-emerald-800">
        Válido
      </span>
    ),
    attributes: {
      ...data.attributes,
      id: data.id,
      uuid: data.uuid,
      relationships: data.relationships,
      fiscal_data: undefined
    }
  }
}

const makeInvalidDeviceDetails = (): HardwareDetails => {
  return {
    valid: false,
    saved: false,
    id: undefined,
    hint: () => (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
        Inválido
      </span>
    ),
    attributes: {}
  }
}

const makeSavedDeviceDetails = (data: Device & { fiscalData?: FiscalData, itemStatus: OrderHardwareStatus }): HardwareDetails => {
  return {
    valid: true,
    saved: true,
    id: data.id,
    hint: () => (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
        Salvo
      </span>
    ),
    attributes: data,
    status: data.itemStatus
  }
}

export function ShowHardwareOrder () {
  const { orderId } = useParams() as unknown as { orderId: number }

  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)
  const [disabled, setDisabled] = useState(false)

  const [steps, setSteps] = useState(orderBaseSteps.map(step => Object.assign({}, step)))

  const [order, setOrder] = useState<Order>()

  const [currentOrderStatus, setCurrentOrderStatus] = useState(OrderStatus.pending)

  const [plan, setPlan] = useState<Partial<Plan>>()
  const [client, setClient] = useState<Partial<Client>>()
  const [currentImei, setCurrentImei] = useState<string>()

  const [cart, setCart] = useState<Set<string>>(new Set())
  const [cartDetails, setCartDetails] = useState<HardwareCart>({})

  const [devicesUnderValidation, setDevicesUnderValidation] = useState<Set<string>>(new Set())
  const [validationQueue, setValidationQueue] = useState<string[]>([])
  const [showConfirmationDialog] = useConfirmationDialog()

  const [selectedDevice, setSelectedDevice] = useState<string>()

  const [currentOrderHardwareStatus, setCurrentOrderItemStatus] = useState<OrderHardwareStatus>('ordered')

  const [currentModal, setCurrentModal] = useState<null|'cancel_fiscal_data'|'update_item_status'>()

  const [fiscalData, setFiscalData] = useState({
    write_off_date: new Date(),
    write_off_invoice: undefined,
    write_off_invoice_motive: undefined
  } as Partial<FiscalData>)

  const [errors, setErrors] = useState<Errors>()


  const updateOrderStatusHistory = useCallback(
    (currentStatus: OrderStatus) => {
      setCurrentOrderStatus(currentStatus)
      setSteps(updateSteps(steps, currentStatus))

      return currentStatus
    },
    [steps]
  )

  const setFocus = useCallback(
    () => {
      const element = document.getElementById('imei')
      element?.focus()
    },
    []
  )

  useEffect(
    () => {
      if (!orderId) return

      const fetchOrderDevices = async (data: Record<string, any>[]) => {
        const details: HardwareCart = {}

        const orderItemDataMap = data.reduce((map, d) => {
          map[d.attributes.item_id] = d.attributes
          return map
        }, {} as Record<string, any>)

        try {
          const items = await listItems(orderId)

          for (const item of items.data) {
            const imei = item.attributes.imei as string
            const itemData = orderItemDataMap[item.id] ?? {}

            details[imei] = makeSavedDeviceDetails({
              ...item.attributes,
              id: item.id,
              relationships: item.relationships,
              fiscal_data: pick(itemData, ['write_off_invoice', 'write_off_date', 'write_off_invoice_motive']),
              itemStatus: itemData.status
            } as any)

            cart.add(imei)
          }

          setCartDetails({ ...cartDetails, ...details })
          setCart(new Set(cart))
        } catch (err) {
          toast.error('Falha ao buscar os rastreadores do pedido!')
        }
      }

      const fetchOrder = async () => {
        try {
          const order = await show(orderId, {
            includes: {
              client: ['id', 'account_name'],
              plan: ['id', 'name', 'fiscal_data', 'meta', 'nature', 'logistics'],
              status: [],
              order_devices: ['item_id', 'status', 'write_off_date', 'write_off_invoice', 'write_off_invoice_motive']
            }
          })

          setOrder(order as Order)

          setPlan({
            id: order?.relationships?.plan?.id,
            uuid: order?.relationships?.plan?.uuid,
            ...(order?.relationships?.plan?.attributes ?? {})
          })

          setClient({
            id: order?.relationships?.client?.id,
            uuid: order?.relationships?.client?.uuid,
            ...(order?.relationships?.client?.attributes ?? {})
          })

          const orderStatus = order?.relationships?.status[0].attributes.status
          const currentStatus = updateOrderStatusHistory(orderStatus)

          if (shouldFetchDevices(currentStatus)) {
            await Promise.all([
              fetchOrderDevices(order?.relationships?.order_devices ?? [])
            ])
          }

          setIsLoading(false)
        } catch (err: any) {
          history.push('/orders')
          toast.error(err.suggestedMessage ?? 'Falha ao buscar os dados do pedido')
        }
      }
      fetchOrder()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderId]
  )

  const onCancelConfirmation = useCallback(
    async () => {
      try {
        await cancel(orderId)
        const data = await show(orderId, {
          attributes: ['id'],
          includes: { status: [] }
        })
        updateOrderStatusHistory(data?.relationships?.status[0].attributes.status)
        toast.success('Pedido cancelado com sucesso!')
      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Não foi possível cancelar o pedido!')
      }
    },
    [orderId, updateOrderStatusHistory]
  )

  const onCancelRequest = useCallback(
    () => {
      if ([OrderStatus.ready_for_pick_up, OrderStatus.shipping, OrderStatus.delivered].includes(currentOrderStatus) && plan?.fiscal_data) {
        setFiscalData({
          write_off_date: undefined,
          write_off_invoice: undefined,
          write_off_invoice_motive: undefined
        })
        setCurrentModal('cancel_fiscal_data')
      } else {
        showConfirmationDialog({
          title: 'Cancelar pedido',
          message: 'Tem certeza que deseja cancelar este pedido?',
          onConfirm: onCancelConfirmation
        })
      }
    },
    [currentOrderStatus, onCancelConfirmation, plan?.fiscal_data, showConfirmationDialog]
  )

  const onApprovalConfirmation = useCallback(
    async () => {
      try {
        await approve(orderId)
        const data = await show(orderId, {
          attributes: ['id'],
          includes: { status: [] }
        })
        updateOrderStatusHistory(data?.relationships?.status[0].attributes.status)
        toast.success('Pedido aprovado com sucesso!')
      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Não foi possível aprovar o pedido!')
      }
    },
    [orderId, updateOrderStatusHistory]
  )

  const onApprovalRequest = useCallback(
    () => {
      showConfirmationDialog({
        title: 'Aprovar pedido',
        message: 'Tem certeza que deseja aprovar este pedido?',
        onConfirm: onApprovalConfirmation
      })
    },
    [onApprovalConfirmation, showConfirmationDialog]
  )

  const onStatusChangeConfirmation = useCallback(
    async (newStatus: OrderStatus) => {
      try {
        newStatus === OrderStatus.delivered
          ? await deliver(orderId)
          : await updateStatus(orderId, newStatus)

        const data = await show(orderId, {
          attributes: ['id'],
          includes: { status: [] }
        })

        updateOrderStatusHistory(data?.relationships?.status[0].attributes.status)
        toast.success('Estado atualizado com sucesso!')
      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Não foi possível atualizar o estado do pedido!')
      }
    },
    [orderId, updateOrderStatusHistory]
  )

  const onStatusChangeRequest = useCallback(
    (status: OrderStatus | '') => {
      if (status === '') return
      showConfirmationDialog({
        title: 'Atualizar estado',
        message: `Alterar o estado do pedido para: ${translations.order_status[status]}?`,
        onConfirm: () => onStatusChangeConfirmation(status)
      })
    },
    [onStatusChangeConfirmation, showConfirmationDialog]
  )

  const validateDevice = useCallback(
    async (imei: string) => {
      let invalid = false

      if (devicesUnderValidation.has(imei) || validationQueue.includes(imei)) {
        return
      } else if (devicesUnderValidation.size < config.max_concurrent_validations) {
        devicesUnderValidation.add(imei)
        setDevicesUnderValidation(new Set(devicesUnderValidation))
        setCartDetails({ ...cartDetails, [imei]: makeUnknownDeviceDetails() })
      } else {
        setValidationQueue([...validationQueue, imei])
        setCartDetails({ ...cartDetails, [imei]: makeUnknownDeviceDetails() })
        return
      }

      try {
        const device = await showFreeDevice({
          attributes: ['id', 'name', 'imei', 'nature'],
          includes: {
            chip: ['serial', 'number'],
            type: ['alias', 'brand_alias', 'name'],
            last_gps: ['last_connection_time', 'last_gps_time'] // TODO: Add 'last_gps_time->iccid' when available
          },
          filters: [
            { key: 'imei', op: 'eq', value: imei },
            { key: 'enterprise_id', op: 'is', value: 'null' },
            { key: 'purchase_tax_note', op: 'isn', value: 'null' },
            { key: 'purchase_tax_note_date', op: 'isn', value: 'null' }
          ]
        })

        // The user has removed this device from the cart while it was still under validation
        if (!devicesUnderValidation.has(imei)) return

        // Validations other than by query
        if (device?.relationships?.type.attributes.name !== plan?.meta?.restriction.device_types.name) {
          invalid = true
        }

        if (device?.attributes.nature?.toLowerCase() !== plan?.nature?.toLowerCase()) {
          invalid = true
        }

        invalid
          ? cartDetails[imei] = makeInvalidDeviceDetails()
          : cartDetails[imei] = makeValidDeviceDetails(device!)

      } catch (err: any) {
        setDisabled(false)
        cartDetails[imei] = makeInvalidDeviceDetails()

        if (err.statusCode !== 404) {
          toast.error(`Um erro desconhecido impediu a validação de: ${imei}!`)
          return
        }
      }

      devicesUnderValidation.delete(imei)

      if (validationQueue.length) {
        const nextImei = validationQueue.shift() as string
        devicesUnderValidation.add(nextImei)
        cartDetails[nextImei] = makeUnknownDeviceDetails()
        setValidationQueue([...validationQueue])
      }

      setDisabled(false)
      setFocus()
      setCartDetails({ ...cartDetails })
      setDevicesUnderValidation(new Set(devicesUnderValidation))
    },
    [cartDetails, devicesUnderValidation, plan?.nature, plan?.meta?.restriction.device_types.name, setFocus, validationQueue]
  )

  const onAddToCart = useCallback(
    async (imei?: string) => {
      if (!imei || imei === '') return

      const pattern = /\d{15}/
      if (!imei.match(pattern)) {
        setErrors({ imei: 'O imei informado deve conter 15 dígitos!' })
        return
      }

      if (cart.has(imei)) {
        setErrors({ imei: 'O rastreador já está no pedido!' })
        return
      }

      if (cart.size >= (order?.quantity ?? 0)) {
        toast.error('O pedido já está cheio!')
        return
      }

      cart.add(imei)
      setCart(new Set(cart))
      setDisabled(true)

      await validateDevice(imei)
      setCurrentImei('')
      setErrors({ imei: null })
    },
    [cart, order?.quantity, validateDevice]
  )

  const onRemoveFrontCart = useCallback(
    (imei: string) => {
      cart.delete(imei)
      setCart(new Set(cart))

      setErrors({ imei: null })

      const validationIndex = validationQueue.indexOf(imei)

      if (validationIndex >= 0) {
        validationQueue.splice(validationIndex, 1)
        setValidationQueue([...validationQueue])
      }

      if (devicesUnderValidation.has(imei)) {
        devicesUnderValidation.delete(imei)
        setDevicesUnderValidation(new Set(devicesUnderValidation))
      }

      if (cartDetails[imei]) {
        delete cartDetails[imei]
        setCartDetails({ ...cartDetails })
      }
    },
    [cart, cartDetails, devicesUnderValidation, validationQueue]
  )

  const onRemoveFromOrderConfirmation = useCallback(
    async (imei: string) => {
      if (!cartDetails[imei] || !cartDetails[imei].id) {
        toast.error('Não foi possível remover o rastreador do pedido!')
        return
      }

      try {
        await removeItems(orderId, [cartDetails[imei].id as number])
        toast.success('Rastreador removido com sucesso!')

        cart.delete(imei)
        setCart(new Set(cart))

        delete cartDetails[imei]
        setCartDetails({ ...cartDetails })

      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Não foi possível remover o rastreador do pedido!')
      }
    },
    [cart, cartDetails, orderId]
  )

  const onRemoveFromOrderRequest = useCallback(
    (imei: string) => {
      showConfirmationDialog({
        title: 'Remover item do pedido',
        message: `Tem certeza que deseja remover o rastreador '${imei}' do pedido?`,
        onConfirm: () => onRemoveFromOrderConfirmation(imei)
      })
    },
    [onRemoveFromOrderConfirmation, showConfirmationDialog]
  )

  const onRefreshComunication = useCallback(
    async () => {
      const deviceIds = []
      for (const item in cartDetails) {
        deviceIds.push(cartDetails[item].id)
      }

      const result = await listDevices({
        attributes: ['id', 'name', 'imei'],
        includes: {
          chips: ['serial', 'number'],
          device_types: ['alias', 'brand_alias'],
          device_last_gps: ['last_connection_time', 'last_gps_time'] // TODO: Add 'last_gps_time->iccid' when available
        },
        filters: [
          { key: 'id', op: 'in', value: deviceIds.join(',') }
        ]
      })

      for (const device of result.data) {
        if (!device.attributes.imei) continue
        cartDetails[device.attributes.imei].attributes.relationships = device.relationships
      }

      setCartDetails({...cartDetails})
    },
    [cartDetails]
  )

  const onSaveFiscalData = useCallback(
    async () => {
      try {
        const payload = {
          attributes: {
            write_off_date: fiscalData.write_off_date,
            write_off_invoice: fiscalData.write_off_invoice,
            write_off_invoice_motive: fiscalData.write_off_invoice_motive
          }
        }

        await updateItemFiscalData(orderId, payload)

        // Atualizar tabela
        for (const index in cartDetails) {
          cartDetails[index].attributes.fiscal_data = {
            write_off_date: fiscalData.write_off_date,
            write_off_invoice: fiscalData.write_off_invoice,
            write_off_invoice_motive: fiscalData.write_off_invoice_motive
          }
        }

        setCartDetails({...cartDetails})

        toast.success('Dados fiscais atualizados com sucesso!')
      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Não foi possível atualizar os dados fiscais!')
        return
      }
    },
    [cartDetails, fiscalData.write_off_date, fiscalData.write_off_invoice, fiscalData.write_off_invoice_motive, orderId]
  )

  const onSave = useCallback(
    async () => {
      try {
        const cartItems = Array.from(cart)
        const cartContainsInvalidItems = cartItems.find((item) => cartDetails[item].id === undefined)

        if (cartContainsInvalidItems) {
          toast.error('Revise os rastreadores inválidos e tente novamente!')
        } else {
          const newItems = cartItems.filter((item) => !cartDetails[item].saved)

          await addItems(orderId, newItems.map((item) => cartDetails[item].id as number))
          toast.success('Rastreadores adicionados com sucesso!')

          const newItemsDetails = newItems.reduce((cart, item) => {
            cart[item] = makeSavedDeviceDetails({
              id: cartDetails[item].id as number,
              ...cartDetails[item].attributes,
              itemStatus: 'ordered'
            } as any)
            return cart
          }, {} as HardwareCart)

          setCartDetails({
            ...cartDetails,
            ...newItemsDetails
          })
        }
      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Falha ao tentar adicionar os rastreadores ao pedido!')
      }
    },
    [cart, cartDetails, orderId]
  )

  const onSaveCancelFiscalData = useCallback(
    async () => {
      const payload = {
        write_off_date: fiscalData.write_off_date || new Date(),
        write_off_invoice: fiscalData.write_off_invoice,
        write_off_invoice_motive: fiscalData.write_off_invoice_motive || writeOffOptions.canceled[0].value || '',
      }

      try {
        await cancel(orderId, payload)

        const data = await show(orderId, {
          attributes: ['id'],
          includes: { status: [] }
        })

        updateOrderStatusHistory(data?.relationships?.status[0].attributes.status)
        toast.success('Pedido cancelado com sucesso!')

        for (const item of Array.from(cart)) {
          cartDetails[item].attributes.fiscal_data = { ...payload }
          cartDetails[item].status = 'canceled'
        }

        setCartDetails({ ...cartDetails })
      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Não foi possível cancelar o pedido!')
      }

      setCurrentModal(null)
    },
    [cart, cartDetails, fiscalData, orderId, updateOrderStatusHistory]
  )

  const onChangeItemStatusRequest = useCallback(
    async (imei: string) => {
      setFiscalData({
        write_off_date: undefined,
        write_off_invoice: undefined,
        write_off_invoice_motive: undefined
      })

      setSelectedDevice(imei)
      setCurrentModal('update_item_status')
    },
    []
  )

  const onSaveChangeItemStatus = useCallback(
    async () => {
      try {
        const payload = {
          attributes: {
            write_off_date: fiscalData.write_off_date || new Date(),
            write_off_invoice: fiscalData.write_off_invoice,
            write_off_invoice_motive: fiscalData.write_off_invoice_motive || writeOffOptions[currentOrderHardwareStatus][0].value || '',
            item_id: cartDetails[selectedDevice as string].id as number
          }
        }

        await updateItemStatus(orderId, currentOrderHardwareStatus, [payload])
        toast.success('Estado alterado com sucesso!')

        cartDetails[selectedDevice as string].attributes.fiscal_data = { ...payload.attributes }
        cartDetails[selectedDevice as string].status = currentOrderHardwareStatus

        setCartDetails({ ...cartDetails })
      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Não foi possível atualizar o estado do rastreador!')
      }

      setCurrentModal(null)
    },
    [cartDetails, currentOrderHardwareStatus, fiscalData, orderId, selectedDevice]
  )

  return (isLoading)
    ? (<LoadPage/>) :
    ( !order
      ? (<NotFoundPage />)
      : (
      <div className="overflow-hidden px-4">
        <div className="flex flex-row justify-between items-center py-4 m-4 animate-fade-in-down">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Pedido: {order.token} {currentOrderStatus === 'canceled' && (
              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                Cancelado
              </span>
           )}
          </h3>

          <div className="gap-x-2 flex flex-row justify-between">
            { includeStatus(currentOrderStatus, [OrderStatus.pending]) && (
                <ProtectedSection roles={[Role.MANAGER, Role.FINANCE, Role.LOGISTICS]}>
                  <SimpleButton onClick={onApprovalRequest}>
                    Aprovar
                  </SimpleButton>
                </ProtectedSection>
              )
            }
            { !includeStatus(currentOrderStatus, [OrderStatus.pending, OrderStatus.canceled, OrderStatus.delivered]) && 
              order.relationships?.plan.attributes.logistics &&
              (
                <ProtectedSection roles={[Role.MANAGER, Role.LOGISTICS]}>
                  <div className="w-52">
                    <SimpleCustomSelect
                      options={orderStatus}
                      value={currentOrderStatus}
                      onChange={(value) => onStatusChangeRequest(value as OrderStatus)}
                    />
                  </div>
                </ProtectedSection>
              )
            }
          </div>
        </div>

        <StepPanel steps={steps}/>

        <ReviewTable order={order} client={client} plan={plan} />

        {/* Order progress session */}
        <ProtectedSection roles={[Role.MANAGER, Role.LOGISTICS]}>
          { includeStatus(currentOrderStatus, [OrderStatus.in_progress, OrderStatus.updating_fiscal_data]) && (
            <div className="flex p-6 m-4 rounded shadow animate-fade-in-down">
              { currentOrderStatus === OrderStatus.in_progress && (
                  <>
                    <div className="flex flex-row w-3/4 items-center space-x-4">
                      <div className="w-1/3">
                        <InputWithValidationError
                          autoFocus
                          label=""
                          type="text"
                          name="imei"
                          id="imei"
                          placeholder="imei"
                          value={currentImei}
                          disabled={disabled || cart.size >= (order?.quantity ?? 0)}
                          onKeyPress={(e: any) => e.key === "Enter" ? onAddToCart(currentImei) : null}
                          onChange={(value) => setCurrentImei(value)}
                          error={errors?.imei}
                        />
                      </div>
                      <div className="mt-1">
                        <SimpleButton
                          disabled={cart.size >= (order?.quantity ?? 0)}
                          onClick={() => onAddToCart(currentImei)}
                        >
                          Adicionar
                        </SimpleButton>
                      </div>
                      <div className="mt-1">
                        {`Adicionados: ${cart.size} de ${order.quantity}`}
                      </div>
                    </div>
                    <div className="flex flex-row-reverse items-center w-1/4">
                      <button
                        type="button"
                        className=" bg-white py-2 px-8 border rounded-md shadow-sm text-sm font-sm text-emerald-700 hover:bg-gray-50 focus:outline-none"
                        onClick={() => onRefreshComunication()}
                      >
                        <div  className="flex flex-row items-center justify-center">
                          <RefreshIcon className="h-4 w-4" aria-hidden="true" />
                          <div className="mx-3">Atualizar</div>
                        </div>
                      </button>
                    </div>
                  </>
                )
              }
              { includeStatus(currentOrderStatus, [OrderStatus.updating_fiscal_data]) && plan?.fiscal_data && (
                  <ProtectedSection roles={[Role.MANAGER, Role.FINANCE, Role.LOGISTICS]}>
                    <div className="flex flex-row w-full space-x-4">
                      <div className="w-1/5">
                        <InputWithValidationError
                          label="Nota fiscal de saída"
                          type="text"
                          name="write_off_invoice"
                          id="write_off_invoice"
                          value={fiscalData.write_off_invoice ?? ''}
                          onChange={(value) => setFiscalData({ ...fiscalData, write_off_invoice: value})}
                        />
                      </div>
                      <div className="w-1/5">
                        <SimpleCustomSelect
                          label="Motivo"
                          options={plan.nature === 'service' ? writeOffMotives.service : writeOffMotives.good}
                          value={fiscalData.write_off_invoice_motive}
                          onChange={(value) => setFiscalData({ ...fiscalData, write_off_invoice_motive: value as WriteOffInvoiceMotives })}
                          />
                      </div>
                      <div className="w-1/5">
                        <DatePickerWithError
                          label="Data da emissão"
                          name="orderedAt"
                          date={fiscalData.write_off_date ? fiscalData.write_off_date : new Date()}
                          onChange={(value) => setFiscalData({ ...fiscalData, write_off_date: value ? new Date(value) : undefined })}
                        />
                      </div>
                      <div className="mt-6">
                        <SimpleButton
                          onClick={() => onSaveFiscalData()}
                        >
                          Salvar
                        </SimpleButton>
                      </div>
                    </div>
                  </ProtectedSection>
                )
              }
            </div>
          )}
        </ProtectedSection>

        <div id="order-items-table">
          { !isEmpty(cartDetails) &&
            !includeStatus(currentOrderStatus, [OrderStatus.pending, OrderStatus.approved, OrderStatus.in_line]) && (
            <div className="flex flex-col px-4 animate-fade-in-down">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                          >
                            { includeStatus(currentOrderStatus, [OrderStatus.in_line, OrderStatus.in_progress, OrderStatus.updating_fiscal_data]) ? "Válido" : "Status"}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                          >
                            Imei / Identificador
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                          >
                            Marca / Modelo
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                          >
                            Última comunicação / GPS
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                          >
                            Serial / Número
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                          >
                            Nota fiscal de saída
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                          >
                            Motivo da saída
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                          >
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-left bg-white divide-y divide-gray-200">
                        {Array.from(cart).reverse().map((imei, index) => (
                          <tr key={index} className={index % 2 ? 'bg-gray-50' : 'bg-white'}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 h-10 w-10">
                                  { includeStatus(currentOrderStatus, [OrderStatus.in_line, OrderStatus.in_progress, OrderStatus.updating_fiscal_data])
                                    ? cartDetails[imei].hint()
                                    : (
                                        <Label
                                          type="item_status"
                                          label={translations['item_status'][cartDetails[imei].status as string]}
                                          value={cartDetails[imei].status}
                                        />
                                      )
                                  }
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">{cartDetails[imei].attributes.imei || imei || '-'}</div>
                              <div className="text-sm text-gray-500">{cartDetails[imei].attributes.name || '-'}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">{cartDetails[imei].attributes.relationships?.type?.attributes?.brand_alias || '-'}</div>
                              <div className="text-sm text-gray-500">{cartDetails[imei].attributes.relationships?.type?.attributes?.alias || '-'}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              <div className="text-sm text-gray-900">{cartDetails[imei].attributes.relationships?.last_gps?.attributes?.last_connection_time || '-'}</div>
                              <div className="text-sm text-gray-900">{cartDetails[imei].attributes.relationships?.last_gps?.attributes?.last_gps_time || '-'}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">{cartDetails[imei].attributes.relationships?.chips?.attributes?.serial || '-'}</div>
                              <div className="text-sm text-gray-500">{cartDetails[imei].attributes.relationships?.chips?.attributes?.number || '-'}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              <div className="text-sm text-gray-900">{cartDetails[imei].attributes.fiscal_data?.write_off_invoice || '-'}</div>
                              <div className="text-sm text-gray-500">{cartDetails[imei].attributes.fiscal_data?.write_off_date ? date(cartDetails[imei].attributes.fiscal_data?.write_off_date) : '-'}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{translations.write_off_motives[cartDetails[imei].attributes.fiscal_data?.write_off_invoice_motive as WriteOffInvoiceMotives] || '-'}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            { includeStatus(currentOrderStatus, [OrderStatus.in_progress]) && !cartDetails[imei].saved && (
                                <button
                                  type="button"
                                  className="mr-2 bg-white py-2 px-4 border rounded-md shadow-sm text-sm font-sm text-emerald-700 hover:bg-gray-50 focus:outline-none"
                                  onClick={() => validateDevice(imei)}
                                >
                                  <RefreshIcon className="h-4 w-4" aria-hidden="true" />
                                </button>
                              )
                            }
                            { includeStatus(currentOrderStatus, [OrderStatus.in_progress]) ?
                                cartDetails[imei].saved
                                  ? (
                                    <button
                                      type="button"
                                      className="bg-white py-2 px-4 border rounded-md shadow-sm text-sm font-medium text-red-700 hover:bg-gray-50 focus:outline-none"
                                      onClick={() => onRemoveFromOrderRequest(imei)}
                                    >
                                      <TrashIcon className="h-4 w-4" aria-hidden="true" />
                                    </button>
                                  )
                                  : (
                                    <button
                                      type="button"
                                      className="bg-white py-2 px-4 border rounded-md shadow-sm text-sm font-medium text-red-700 hover:bg-gray-50 focus:outline-none"
                                      onClick={() => onRemoveFrontCart(imei)}
                                    >
                                      <XIcon className="h-4 w-4" aria-hidden="true" />
                                    </button>
                                  )
                                : null
                            }
                            { includeStatus(currentOrderStatus, [OrderStatus.delivered]) && (
                                <ProtectedSection roles={[Role.MANAGER, Role.FINANCE, Role.LOGISTICS]}>
                                  { cartDetails[imei].status === 'ordered' && (
                                    <SimpleButton
                                      className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                      onClick={() => onChangeItemStatusRequest(imei)}
                                    >
                                      <PencilIcon className="h-4 w-4" aria-hidden="true" />
                                    </SimpleButton>
                                  )}
                                </ProtectedSection>
                              )
                            }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            )
          }

          <ProtectedSection roles={[Role.MANAGER, Role.LOGISTICS]}>
            {(currentOrderStatus === OrderStatus.in_progress) && (
              <div className="m-4 flex justify-end animate-fade-in-down">
                <button
                  type="button"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                  onClick={() => onSave()}
                >
                  Salvar
                </button>
              </div>
            )}
          </ProtectedSection>
        </div>

        {/* Modal de atualizacao de nota fiscal */}
        <ProtectedSection roles={[Role.MANAGER, Role.FINANCE, Role.LOGISTICS]}>
          { plan?.fiscal_data && (
            <>
            { [OrderStatus.ready_for_pick_up, OrderStatus.shipping, OrderStatus.delivered].includes(currentOrderStatus) && (
                <Modal open={currentModal === 'cancel_fiscal_data'} title='Mudança de estado' onClose={() => setCurrentModal(null)}>
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-6">
                      <div className="mt-1">
                        <InputWithValidationError
                          label="Nota fiscal de cancelamento"
                          type="text"
                          name="write_off_invoice"
                          id="write_off_invoice"
                          value={fiscalData.write_off_invoice}
                          onChange={(value) => setFiscalData({ ...fiscalData, write_off_invoice: value })}
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="mt-1">
                        <DatePickerWithError
                          label="Data da emissão"
                          name="write_off_date"
                          date={fiscalData.write_off_date ? fiscalData.write_off_date : new Date()}
                          onChange={(value) => setFiscalData({ ...fiscalData, write_off_date: value ? new Date(value) : undefined })}
                        />
                      </div>
                    </div>
                    <div className="col-span-12">
                      <RadioListWithDescription
                        label='Motivo'
                        options={writeOffMotives.cancel}
                        value={fiscalData.write_off_invoice_motive ?? writeOffMotives.cancel[0].value}
                        onChange={(value) => setFiscalData({ ...fiscalData, write_off_invoice_motive: value as WriteOffInvoiceMotives })}
                      />
                    </div>
                  </div>

                  <div className="m-4 flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                      onClick={() => { setCurrentModal(null) }}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                      onClick={onSaveCancelFiscalData}
                    >
                      Salvar
                    </button>
                  </div>
                </Modal>
              )
            }

            { currentOrderStatus === OrderStatus.delivered && (
                <Modal open={currentModal === 'update_item_status'} title='Mudança de estado' onClose={() => setCurrentModal(null)}>
                  <div className="grid grid-cols-12 gap-4 mt-4">
                    <div className="col-span-12">
                      <SimpleCustomSelect
                        label="Novo estado"
                        options={orderHardwareStatus}
                        value={currentOrderHardwareStatus}
                        onChange={(value) => setCurrentOrderItemStatus(value as OrderHardwareStatus)}
                      />
                    </div>
                    <div className="col-span-6">
                      <div className="mt-1">
                        <InputWithValidationError
                          label="Nota fiscal"
                          type="text"
                          name="write_off_invoice"
                          id="write_off_invoice"
                          value={fiscalData.write_off_invoice ?? ''}
                          onChange={(value) => setFiscalData({ ...fiscalData, write_off_invoice: value })}
                        />
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="mt-1 relative">
                        <DatePickerWithError
                          label="Data da emissão"
                          name="write_off_date"
                          date={fiscalData.write_off_date ? fiscalData.write_off_date : new Date()}
                          onChange={(value) => setFiscalData({ ...fiscalData, write_off_date: value ? new Date(value) : undefined })}
                        />
                      </div>
                    </div>
                    <div className="col-span-12">
                      <RadioListWithDescription
                        label='Motivo'
                        options={writeOffOptions[currentOrderHardwareStatus] ?? writeOffOptions.default}
                        value={fiscalData.write_off_invoice_motive ?? writeOffMotives.good[0].value}
                        onChange={(value) => setFiscalData({ ...fiscalData, write_off_invoice_motive: value as WriteOffInvoiceMotives })}
                      />
                    </div>
                  </div>

                  <div className="m-4 flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                      onClick={() => { setCurrentModal(null) }}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none"
                      onClick={onSaveChangeItemStatus}
                    >
                      Salvar
                    </button>
                  </div>
                </Modal>
              )
            }
            </>
          )}
        </ProtectedSection>

        { currentOrderStatus !== OrderStatus.canceled && (
            <div className="py-6 gap-x-2 flex flex-row justify-between animate-fade-in-down">
              <ProtectedSection roles={[Role.MANAGER, Role.FINANCE, Role.LOGISTICS]}>
                <div className="ml-3 text-left text-md">
                  <label className="font-medium text-gray-700">
                    Cancelar pedido
                  </label>
                  <p className="p-4 text-gray-500">
                    Atenção: ao cancelar o pedido todos os rastreadores salvos serão deletados.
                  </p>

                  <div className="">
                    <CancelButton label="Cancelar" onClick={onCancelRequest} >
                    </CancelButton>
                  </div>
                </div>
              </ProtectedSection>
            </div>
          )
        }
      </div>
    ))
}
