import { Button, Close } from "components/Buttons"
import { FormSection } from "components/Form"
import { DatePickerWithError } from "components/FormInputs/DatePicker"
import { PageHeader } from "components/PageHeader"
import { ReadResult } from "interfaces/queryOptions"
import { Plan, Client } from "models"
import { ClientSearch, PlanSearch } from "modules/Orders/components/Searchs"
import { useCallback, useState } from "react"
import { toast } from "react-toastify"
import { Errors } from "types"
import { getFirstDayOfMonth } from "utils/datetime"
import { createSubscription } from "modules/Clients/services/create"
import { useHistory } from "react-router-dom"
import { InputWithAddon, SimpleCustomSelect } from "components/FormInputs"
import { amount, cleanAmount, cleanPercentageAmount, currency } from "utils"
import { validate } from "modules/Orders/validation"
import { CreateOrderAttributes } from "modules/Orders/types"
import { fineModes } from "components/FormInputs/options"

export function Create() {
  const history = useHistory()

  const [orderedAt, setOrderedAt] = useState<Date | null>(new Date())
  const [accessPlan, setAccessPlan] = useState<ReadResult<Plan>>()
  const [client, setClient] = useState<ReadResult<Client>>()

  const [isSaving, setIsSaving] = useState(false)
  const [shouldClear, setShouldClear] = useState(false)
  const [errors, setErrors] = useState<Errors>({})

  const [formData, setFormData] = useState<Record<string, any>>({
    discount: '',
    minimum_invoice_amount: '',
    fine_mode: '',
    fine_amount: ''
  })

  const onSave = useCallback(
    async () => {
      if (!client || !accessPlan || !orderedAt)
        return

      setIsSaving(true)
      try {
        await createSubscription(
          client.id, 
          accessPlan.id, 
          { 
            quantity: 1, 
            ordered_at: orderedAt, 
            discount: formData.discount ? cleanAmount(formData.discount) : null, 
            minimum_invoice_amount: formData.minimum_invoice_amount ? cleanAmount(formData.minimum_invoice_amount) : null,
            fine_mode: (formData.fine_mode && formData.fine_amount !== '0,00') ? formData.fine_mode : null,
            fine_amount: (formData.fine_mode && formData.fine_amount !== '0,00') ? (formData.fine_mode === 'fixed' ? cleanAmount(formData.fine_amount) : cleanPercentageAmount(formData.fine_amount)) : null
          }
        )

        toast.success('Assinatura criada com sucesso')
        setIsSaving(false)
        history.push('/subscriptions')
      } catch (err: any) {
        setIsSaving(false)
        toast.error(err.suggestedMessage ?? 'Falha ao tentar salvar os dados da assinatura')
      }
    },
    [client, accessPlan, createSubscription, orderedAt, formData]
  )

  const onAttributeChange = useCallback(
    (attr: keyof CreateOrderAttributes, value: any): void => {
      const error = validate(attr as any, value)
  
      setFormData((prevFormData) => {
        if (attr === 'fine_mode') {
          return { ...prevFormData, [attr]: value }
        } else {
          return { ...prevFormData, [attr]: amount(value.replace(/[^0-9]/g, '')) }
        }
      })
  
      setErrors((prevErrors) => ({ ...prevErrors, [attr]: error }));
    },
    []
  )
  
  const onPlanSelection = useCallback(
    (plan: any): void => {
      setAccessPlan(plan)
      setFormData({
          discount: '',
          minimum_invoice_amount: amount(plan.attributes.minimum_invoice_amount),
          fine_mode: plan.attributes.fine_mode,
          fine_amount: plan.attributes.fine_mode === 'fixed' ? amount(plan.attributes.fine_amount) : plan.attributes.fine_amount
        })
    }, [])

  return (
    <>
    <PageHeader title="Assinaturas" action="Nova"/>

    <div className="flex flex-row space-x-10 px-4 animate-fade-in-down">
      <div className="w-1/2">
        <form onSubmit={(e) => { e.preventDefault(); onSave() }}>
          <FormSection title="Cliente">
            <ClientSearch
              onSelect={setClient}
              error={errors.client}
              planCountryId={accessPlan?.relationships?.country.id}
            />
          </FormSection>

          <FormSection title="Plano">
            <PlanSearch
              clear={shouldClear}
              setClear={setShouldClear}
              onSelect={onPlanSelection}
              onChange={setAccessPlan}
              error={errors.plan}
              clientCountryId={client?.relationships?.country.id}
              subscription={true}
            />
          </FormSection>

          
          <FormSection title="Assinatura">
            {(accessPlan?.attributes.usage_type === 'metered' ||
              accessPlan?.attributes.usage_type === 'licensed') && 
            <InputWithAddon
              label="Desconto"
              type="text"
              frontAddon={currency(accessPlan?.attributes.currency)}
              disabled={accessPlan?.attributes.restricted}
              name="discount"
              value={formData.discount}
              onChange={(value) =>
                onAttributeChange("discount", value)
              }
            />}
            <SimpleCustomSelect
              label="Tipo de multa"
              options={fineModes}
              value={formData?.fine_mode}
              onChange={(value) => onAttributeChange('fine_mode', value)}
            />
            <InputWithAddon
              label="Valor da multa (por unidade)"
              name="fine_amount"
              type="text"
              pattern="[0-9]+([\.,][0-9]+)?"
              value={formData?.fine_amount}
              frontAddon={formData.fine_mode === 'fixed' ? currency(accessPlan?.attributes.currency) : '%'}
              onChange={(value) => onAttributeChange('fine_amount', value)}
              error={errors.amount}
            />
            <InputWithAddon
              label="Valor mínimo da fatura"
              name="minimum_invoice_amount"
              type="text"
              pattern="[0-9]+([\.,][0-9]+)?"
              value={formData.minimum_invoice_amount}
              frontAddon={currency(accessPlan?.attributes.currency)}
              onChange={(value) =>
                onAttributeChange('minimum_invoice_amount', value)
              }
              error={errors.minimum_invoice_amount}
            />
          </FormSection> 

        </form>

        <div className="mt-5 w-1/4">
          <DatePickerWithError
            label="Pedido em"
            name="orderedAt"
            date={orderedAt}
            minDate={getFirstDayOfMonth()}
            maxDate={new Date()}
            onChange={setOrderedAt}
          />
        </div>

        <div className="px-4 mt-8 flex space-x-4 justify-end animate-fade-in-down">
          <Close linkTo="/subscriptions" />

          <Button
            disabled={isSaving}
            type="submit"
            onClick={onSave}
            label="Assinar"
          />
        </div>
      </div>
    </div>
    </>
  )
}