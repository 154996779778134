import { date, time } from "utils"
import { Client, Order, Plan } from 'models'
import { translations } from '../../translations'

interface ReviewTableProps {
  order: Partial<Order>
  client: Partial<Client> | undefined
  plan: Partial<Plan> | undefined
}

export function ReviewTable ({ order, client, plan }: ReviewTableProps) {
  return (
    <dl className="rounded shadow mx-4 my-8 animate-fade-in-down">
      <div className="bg-gray-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Cliente</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{client?.account_name}</dd>
      </div>
      <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Plano</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{plan?.name}</dd>
      </div>
      <div className="bg-gray-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Quantidade</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{order.quantity}</dd>
      </div>
      <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Método de envio</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{translations.shipping_methods[order.shipping_method as string]}</dd>
      </div>
      <div className="bg-gray-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Data do pedido</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {`${date(order.ordered_at)} ${time(order.ordered_at)}`}
        </dd>
      </div>
      <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Data de criação</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{`${date(order.created_at)} ${time(order.created_at)}`}</dd>
      </div>
      <div className="bg-gray-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Data de ativação</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{`${date(order.activated_at)} ${time(order.activated_at)}`}</dd>
      </div>
    </dl>
  )
}
